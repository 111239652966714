@import '../vars';

.screen-5 {
    padding: 40px 0;
    position: relative;
    background: $accentColor;
    z-index: 10;
    color: $mainColor;
    h2 {
        font-size: 40px;
        line-height: 45px;
        margin-bottom: 20px;
    }
    &__content {
        margin-bottom: 20px;
    }
    .btn {
        width: 100%;
    }
}

@media (min-width: $screenTablet) {
    .screen-5 {
        max-width: 1440px;
        margin: auto;
        text-align: center;
        padding: 80px 0;

        &__content {
            width: 720px;
            margin: 0 auto 20px;
        }
        .btn {
            width: 161px;
        }
    }
}

@media (min-width: $screenDesktop) {
    .screen-5 {
        &__content {
            position: relative;
        }
        &__img-1 {
            width: 289px;
            height: 228px;
            background-image: url('../img/f1.svg');
            position: absolute;
            left: 54px;
            top: 80px;
            opacity: 0.1;
        }
        &__img-2 {
            width: 182px;
            height: 218px;
            background-image: url('../img/f2.svg');
            position: absolute;
            right: 147px;
            top: 64px;
            opacity: 0.1;
        }
    }
}

@media (min-width: 1360px) {
    .screen-5 {
        &__img-1 {
            opacity: 1;
        }
        &__img-2 {
            opacity: 1;
        }
    }
}
