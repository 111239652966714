@import '../vars';

.screen-4-1 {
    color: $mainColor;
    padding: 40px 0;
    position: relative;
    &__header {
        margin-bottom: 40px;
    }
    &__content {
        max-width: 62.5%;
    }
    &__picture {
        position: absolute;
        height: 272px;
        top: 25px;
        right: 0;
        overflow: hidden;
        img {
            display: block;
            width: auto;
            height: 100%;
            transform: translate(30px, 0);
        }
    }
}

@media (min-width: $screenTablet) {
    .screen-4-1 {
        padding: 80px 0;
        .container {
            display: flex;
            max-width: 990px;
            margin: auto;
            justify-content: center;
            align-items: center;
        }
        &__header {
            margin-bottom: 0;
            text-align: right;
            width: 50%;
            margin-right: 40px;
        }
        &__content {
            width: 50%;
            margin-left: 40px;
        }
        &__picture {
            display: none;
        }
    }
    .screen-4-1__content__header {
        margin-bottom: 40px;
    }
}

@media (min-width: $screenDesktop) {
    .screen-4-1 {
        height: 650px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 0;
        .container {
            max-width: 1200px;
        }
        &__header {
            padding-right: 10%;
        }
        &__content {
            padding-left: 10%;
        }
        &__picture {
            display: block;
            width: 310px;
            height: 552px;
            left: 50%;
            top: 50%;
            margin-top: -276px;
            margin-left: -155px;
        }
        &__bubles {
            position: absolute;
            top: 100%;
            margin-top: -108px;
            left: 50%;
            margin-left: -186px;
            width: 332px;
            height: 679px;
            background-image: url('../img/screen-4-1-bubles.png');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}


.screen-4-2 {
    background: $accentColor2;
    .container {
        padding: 0;
    }

}

.screen-4-2__half {
    display: flex;
    align-items: center;
    position: relative;
    h3 {
        margin-bottom: 20px;
    }
    ul li {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}



.screen-4-2__half__picture {
    width: 89px;
    height: 255px;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    z-index: 10;
    
    img {
        height: 100%;
        width: auto;
    }
}

.screen-4-2__half__content {
    z-index: 10;
    position: relative;
}

.screen-4-2__half:nth-child(1) {
    padding: 20px 0;
    color: $mainColor;
    .screen-4-2__half__picture {
        margin-right: 20px;
        img {
            position: absolute;
            right: 0;
            top: 0;
        }
    }   
    .screen-4-2__half__content {
        padding-right: 15px;
    } 
}

.screen-4-2__half:nth-child(2) {
    padding: 40px 0;
    flex-direction: row-reverse;
    .screen-4-2__half__picture {
        margin-left: 20px;
        img {
            position: absolute;
            left: 0;
            top: 0;
        }
    }  
    .screen-4-2__half__content {
        padding-left: 15px;
    }   
}

.screen-4-2__half__background {
    background: $mainColor;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

@media (min-width: $screenTablet) {
    .screen-4-2 {
        background: linear-gradient(to right, $accentColor2 0%, $accentColor2 50%, $mainColor 50%, $mainColor 100%);
        .container {
            display: flex;
            padding: 0 15px;
        }
    }

    .screen-4-2__half {
        width: 50%;
        justify-content: center;

    }

    .screen-4-2__half__picture {
        width: 165px;
        height: 345px;
        img {
            display: block;
            position: static;
            width: 100%;
            height: auto;
        }
    }

    .screen-4-2__half:nth-child(1), .screen-4-2__half:nth-child(2) {
        padding: 65px 15px;
        flex-direction: row;
        .screen-4-2__half__content {
            padding-left: 0;
            padding-right: 0;
        }  
        .screen-4-2__half__picture {
            margin-left: 0;
            margin-right: 40px;
        }
    }
}