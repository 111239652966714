@import '../vars';

.screen-2 {
    color: $mainColor;
    margin-bottom: 40px;
    p {
        margin-bottom: 20px;
        text-align: justify;
    }
}

.screen-2__heading {
    margin-bottom: 20px;
}

.screen-2__bubles {
    text-align: center;
    span {
        display: inline-block;
        margin: 20px 0;
    }
}

@media (max-width: $screenTablet - 1px) {
    .screen-2__bubles_m1 {
        height: 167px;
        margin: auto;
        background-image: url('../img/secure_m.svg');
        width: 264px;
        margin: auto;
        background-repeat: no-repeat;
    }

    .screen-2__bubles_m2 {
        height: 60px;
        margin: auto;
        background-image: url('../img/secure_m.svg');
        width: 264px;
        margin: auto;
        background-repeat: no-repeat;
    }
}

@media (min-width: $screenTablet) {
    .screen-2 {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 80px;
    }
    .screen-2__content {
        font-size: 18px;
        line-height: 140%;
    }
    .screen-2__bubles {
        width: 668px;
        position: relative;
        height: 388px;
        background-image: url('../img/secure_d.svg');
        background-repeat: no-repeat;
        span {
            position: absolute;
            background: $accentColor;
            font-size: 18px;
            line-height: 140%;
            left: 50%;
            transform: translateX(-50%);
            bottom: 69px;
            padding: 0 5px;
        }
    }
}

@media (min-width: $screenDesktopSmall) {
    .screen-2 {
        max-width: 100%;
        .container {
            display: flex;
            justify-content: space-between;
        }
    }

    .screen-2__content {
        width: 668px;
    }
    .screen-2__heading {
        max-width: 370px;
    }
}
