@import '../vars';

.screen-3 {
    background: $mainColor;
    padding-bottom: 40px;
    position: relative;
    overflow: hidden;
    // max-width: 1440px;
    margin: auto;
}

.screen-3__img-wrap {
    margin: 0 -15px;
    background: linear-gradient(to right, $accentColor 0%, $accentColor 50%, $mainColor 50%, $mainColor 100%);
    position: relative;
    margin-bottom: 20px;
    &:after {
        content: '';
        width: 100%;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 18%;
        background: $mainColor;
    }
}

.screen-3__img {
    width: auto;
    height: auto;
    max-width: 100%;
    margin: auto;
    display: block;
    position: relative;
    z-index: 1;
}

.screen-3-layers {
    position: relative;
}
.layer {
    margin: 0 auto 40px;
    &:last-child {
        margin-bottom: 0;
    }
}

.layer-content {
    max-width: 383px;
}

.layer-number {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #414042;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
}

.layer-title {
    margin-bottom: 10px;
}

.screen-3__bubles {
    height: 368px;
    width: 179px;
    background-image: url('../img/screen-3-bubles.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: -115px;
    bottom: 0;
}

@media (min-width: $screenTablet) {
    .screen-3__img-wrap {
        display: none;
    }
    .screen-3-layers {
        padding-top: 40px;
    }
}

@media (min-width: $screenDesktopSmall) {
    .screen-3 {
        overflow: visible;
        background: linear-gradient(to right, $accentColor 0%, $accentColor 33.75%, $mainColor 33.75%, $mainColor 100%);
        .container {
            position: relative;
        }
    }

    .screen-3__img-wrap {
        display: block;
        background: transparent;
        margin: 0;
        position: absolute;
        left: 0;
        top: -61px;
        width: 570px;
        &:after {
            display: none;
        }
    }
    .screen-3-layers {
        padding-left: 50%;
        padding-top: 120px;
    }
    .screen-3__bubles {
        width: 180px;
        height: 679px;
        right: 0;
        bottom: -237px;
    }

    .layer {
        display: flex;
    }
    .layer-number {
        margin-right: 50px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 19px;
            width: 200px;
            height: 1px;
            background: $accentColor;
            right: 100%;
        }
    }
}

@media (min-width: $screenDesktop) {
    .screen-3 {
        padding-bottom: 100px;
    }
    .screen-3__bubles-2 {
        width: 331px;
        height: 678px;
        position: absolute;
        left: 0;
        top: 120px;
        background-image: url('../img/screen-3-bubles-2.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
}
