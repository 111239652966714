@import '../vars';
.first-screen {
    background: $mainColor;
    padding: 30px 0;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    h1 {
        padding-bottom: 20px;
    }
}
.first-screen__text {
    margin-bottom: 20px;
}

@media (min-width: $screenTablet) {
    .colored-row-1 {
        background: linear-gradient(to right, $accentColor 0%, $accentColor 65%, $mainColor 65%, $mainColor 100%);
    }
    .colored-row-2 {
        background-color: $mainColor;
    }
    .first-screen {
        background-color: transparent;
        padding: 0;
        position: relative;
        margin-bottom: 80px;
        h1 {
            color: $mainColor;
            padding-bottom: 40px;
        }
    }
    .first-screen__logo {
        margin-bottom: 50px;
    }
    .first-screen__text {
        max-width: 500px;
        padding: 40px 0 40px 0;
        font-size: 20px;
        line-height: 140%;
    }
    .first-screen__lock {
        display: block;
        position: absolute;
        right: 0;
        top: 35px;
        width: 35%;
        img {
            display: block;
            width: 82%;
            height: auto;
            margin: auto;
        }
    }
}

@media (min-width: $screenDesktopSmall) {
    .first-screen__lock {
        display: block;
        position: absolute;
        right: 0;
        top: 35px;
        width: 35%;
        img {
            display: block;
            width: 82%;
            height: auto;
            margin: auto;
        }
    }
    .first-screen__text {
        max-width: 636px;
        padding: 40px 0 104px 0;
        font-size: 30px;
        line-height: 140%;
    }
}
